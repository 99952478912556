body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', 'Helvetica', 'Arial', sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex-grow: 1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Añade esto al final del archivo */
::selection {
  background-color: #FFB74D; /* Naranja claro */
  color: #000000; /* Negro para máximo contraste */
}

/* Estilos para el calendario (react-big-calendar) */
.rbc-calendar {
  font-family: 'Poppins', sans-serif;
}

.rbc-toolbar {
  margin-bottom: 20px;
}

.rbc-toolbar button {
  color: #333;
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 4px;
  font-weight: 500;
}

.rbc-toolbar button:hover {
  background-color: #f5f5f5;
}

.rbc-toolbar button.rbc-active {
  background-color: #FF5722;
  color: white;
  border-color: #FF5722;
}

.rbc-toolbar button.rbc-active:hover {
  background-color: #E64A19;
}

.rbc-today {
  background-color: rgba(255, 87, 34, 0.1);
}

.rbc-current-time-indicator {
  background-color: #FF5722;
}

.rbc-event {
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rbc-header {
  font-weight: 600;
  padding: 10px 0;
}

.rbc-month-view .rbc-header {
  border-bottom: 1px solid #eee;
}

.rbc-time-slot {
  min-height: 22px;
}

.rbc-event-content {
  padding: 2px 8px;
  font-size: 0.85rem;
}

.rbc-day-slot .rbc-event {
  border: none;
}

.rbc-month-view .rbc-date-cell {
  padding: 5px;
  text-align: center;
}

.rbc-month-view .rbc-date-cell > a {
  font-weight: normal;
  color: #333;
}

.rbc-off-range-bg {
  background-color: #f8f8f8;
}

.rbc-time-view .rbc-day-bg + .rbc-day-bg {
  border-left: 1px solid #eee;
}

/* Estilos responsive */
@media (max-width: 768px) {
  .rbc-toolbar {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .rbc-toolbar .rbc-toolbar-label {
    margin: 10px 0;
  }
  
  .rbc-toolbar button {
    margin-bottom: 5px;
  }
  
  .rbc-calendar {
    height: 500px !important;
  }
}
